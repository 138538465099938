import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { POST, GET, PUT, DELETE, PATCH },
  API_END_POINT: {
    GET_KNOWLEDGE_BASES,
    GET_KNOWLEDGE_BASE_BY_ID,
    KNOWLEDGE_BASE_IMPORT_FILE,
    QUERY,
    CHATBOT,
    APPS,
    MANAGE_APPS,
    QUERY_HISTORY,
    DELETE_APP,
    IMPORT_KNOWLEDGE_BASE,
    BOT_ICON,
    GET_API_KEY,
    BOT_LOGO,
    SUGGESTIONS,
    SAVE_SUGGESTIONS,
    LIKE_DISLIKE_QUERY,
    VIEW_EXTRACTED_DATA,
    CHAT_USER,
    QUERY_SESSION,
    CHECK_CSV_EXIST,
    GET_SCHEMA_DETAILS,
    REGENERATE_API_KEY,
    BOT_PERFORMANCE,
    REMOVE_SCHEMA,
    RUN_PERFORMANCE_TEST,
    REVERT_APP_SETTINGS,
    ACCOUNT_COMPLETION,
    ACTION_STEPS,
  },
} = httpConstants;
const { AUTH0_ID_TOKEN } = keyTypeConstants;
export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export function getHeaderForUploadFile() {
  return {
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

const fetchData = async (url, reqQuery, requestData, method) => {
  const query = new URLSearchParams(reqQuery).toString();
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_CONTENT_MICROSERVICE_URL + `${url}?${query}`, {
      method: method,
      headers: getHeaderForUploadFile(),
      body: requestData,
    })
      .then(async (data) => {
        try {
          const res = await data.json();
          if (!res.success) {
            if (Number(res.responseCode) === 401) {
              return reject();
            }
            const error =
              res.responseCode === 500 ? res : res?.message || data.statusText;
            return reject(error);
          }
          return resolve(res.responseData);
        } catch (err) {
          reject(err);
        }
      })
      .catch(reject);
  });
};

export default class ContentService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;
  localUrl = "http://localhost:3010";

  getApps = async (data) => {
    const query = !!data
      ? `${APPS}?${new URLSearchParams(data).toString()}`
      : APPS;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  knowledgeBaseImportFile = async (reqQuery, requestData) => {
    return fetchData(KNOWLEDGE_BASE_IMPORT_FILE, reqQuery, requestData, POST);
  };

  updateBotIcon = async (reqQuery, requestData) => {
    return fetchData(BOT_ICON, reqQuery, requestData, PUT);
  };

  updateBotLogo = async (reqQuery, requestData) => {
    return fetchData(BOT_LOGO, reqQuery, requestData, PUT);
  };

  getQueryHistory = async (queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${QUERY_HISTORY}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  deleteApp = async (appId, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `${DELETE_APP}/${appId}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateApp = async (data) => {
    if (data && data.themeName === "") {
      delete data.themeName;
    }
    return new Promise((resolve, reject) => {
      httpService(PUT, this.baseUrl + `/${"manage-app"}`, data, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getKnowledgeBases = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${GET_KNOWLEDGE_BASES}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getKnowledgeBaseById = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
          `${GET_KNOWLEDGE_BASE_BY_ID}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  knowledgeBaseImportDoc = async (reqQuery, requestData) => {
    const query = new URLSearchParams(reqQuery).toString();
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        process.env.REACT_APP_CONTENT_MICROSERVICE_URL +
          `${KNOWLEDGE_BASE_IMPORT_FILE}?${query}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  manageCreateApps(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + MANAGE_APPS, requestData, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  deleteAppById = async (requestData) => {
    let url = this.baseUrl + httpConstants.API_END_POINT.MANAGE_APPS;
    return new Promise((resolve, reject) => {
      httpService(
        httpConstants.METHOD_TYPE.DELETE,
        url,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getAppsById(id, requestData) {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        `${this.baseUrl}${MANAGE_APPS}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  queryChatbot = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + QUERY, data, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  querySuggestions = async (reqQuery) => {
    const query = new URLSearchParams(reqQuery).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${SUGGESTIONS}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateSuggestions = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(PUT, this.baseUrl + `${SAVE_SUGGESTIONS}`, data, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getApp = async (id, queryData) => {
    const query = `${CHATBOT}/${id}?${new URLSearchParams(
      queryData
    ).toString()}`;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  saveKnowledgeBaseByReferId = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + IMPORT_KNOWLEDGE_BASE,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getTenentQueryHistory = async (tenantId) => {
    const query = `?tenantId=${tenantId}`;

    const res = await httpService(
      httpConstants.METHOD_TYPE.GET,

      this.baseUrl + httpConstants.API_END_POINT.GET_QUERY_HISTORY + query,

      null,

      getHeaders()
    );

    if (!res.success || res.responseCode !== 200) throw res;

    return res?.responseData;
  };
  getTenetBotPerformance = async (tenantId) => {
    const query = `?tenantId=${tenantId}`;

    const res = await httpService(
      httpConstants.METHOD_TYPE.GET,

      this.baseUrl + httpConstants.API_END_POINT.GET_PERFORMANCE_TEST + query,

      null,

      getHeaders()
    );

    if (!res.success || res.responseCode !== 200) throw res;

    return res?.responseData;
  };
  getTenetGoldenQuestion = async (tenantId) => {
    const query = `?tenantId=${tenantId}`;

    const res = await httpService(
      httpConstants.METHOD_TYPE.GET,

      this.baseUrl + httpConstants.API_END_POINT.GET_GOLDEN_QUESTION + query,

      null,

      getHeaders()
    );

    if (!res.success || res.responseCode !== 200) throw res;

    return res?.responseData;
  };

  getApiKey = async (tenantId, userId) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + GET_API_KEY + `?tenantId=${tenantId}&userId=${userId}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getSchemaList = async ({ tenantId, knowledgeBaseId, schemaId }) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl +
          GET_SCHEMA_DETAILS +
          `?knowledgeBaseId=${knowledgeBaseId}&tenantId=${tenantId}&schemaId=${schemaId}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  removeSchema = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + REMOVE_SCHEMA,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateLikeDislike = async (requestData) => {
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + LIKE_DISLIKE_QUERY, {
        method: PUT,
        body: JSON.stringify(requestData),
        headers: getHeaders(),
      })
        .then(async (data) => {
          try {
            const res = await data.json();
            if (!res.success) {
              if (Number(res.responseCode) === 401) {
                return reject();
              }
              const error =
                res.responseCode === 500
                  ? res
                  : res?.message || data.statusText;
              return reject(error);
            }
            return resolve(res.responseData);
          } catch (err) {
            console.log("err", err);
            reject(err);
          }
        })
        .catch(reject);
    });
  };

  viewExtractedData = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + VIEW_EXTRACTED_DATA,
        requestData,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  regenerateApiKey = async (requestData) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${requestData.ApiKey}`,
    };
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + REGENERATE_API_KEY, requestData, headers)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  queryFlow = async (data) => {
    const query = `?${new URLSearchParams(data).toString()}`;
    const res = await httpService(
      httpConstants.METHOD_TYPE.GET,
      this.baseUrl + httpConstants.API_END_POINT.QUERY_FLOW + query,
      null,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  chatUserInfo = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + CHAT_USER, requestData, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getQuerySession = async (queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${QUERY_SESSION}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  checkIsCSVExist = async (query) => {
    const res = await httpService(
      GET,
      `${this.baseUrl}${CHECK_CSV_EXIST}?${query}`,
      null,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  getBotPerformance = async (queryData) => {
    const query = new URLSearchParams(queryData).toString();
    const res = await httpService(
      GET,
      `${this.baseUrl}${BOT_PERFORMANCE}?${query}`,
      {},
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  runPerformanceTest = async (requestData) => {
    const res = await httpService(
      POST,
      `${this.baseUrl}${RUN_PERFORMANCE_TEST}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  revertAppSettings = async (requestData) => {
    const res = await httpService(
      PUT,
      `${this.baseUrl}${REVERT_APP_SETTINGS}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  deleteVersion = async (id, requestData) => {
    const res = await httpService(
      DELETE,
      `${this.baseUrl}${BOT_PERFORMANCE}/${id}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  updateAccuracy = async (id, requestData) => {
    const res = await httpService(
      PATCH,
      `${this.baseUrl}${BOT_PERFORMANCE}/${id}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  // actionStepsConfigure = async (requestData) => {
  //   return new Promise((resolve, reject) => {
  //     httpService(
  //       httpConstants.METHOD_TYPE.POST,
  //       this.baseUrl + ACTION_STEPS,
  //       requestData,
  //       getHeaderForUploadFile()
  //     )
  //       .then((res) => {
  //         return resolve(res?.responseData);
  //       })
  //       .catch(reject);
  //   });
  // };

  actionStepsConfigure = async ( requestData) => {
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + ACTION_STEPS , {
        method: httpConstants.METHOD_TYPE.POST,
        headers: getHeaderForUploadFile(),
        body: requestData,
      })
        .then(async (data) => {
          try {
            const res = await data.json();
            if (!res.success) {
              if (Number(res.responseCode) === 401) {
                return reject();
              }
              const error =
                res.responseCode === 500 ? res : res?.message || data.statusText;
              return reject(error);
            }
            return resolve(res.responseData);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  };

  getAccountCompletion = async (queryParams) => {
    const query = new URLSearchParams(queryParams).toString();
    const res = await httpService(
      GET,
      `${this.baseUrl}${ACCOUNT_COMPLETION}?${query}`,
      null,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

}

