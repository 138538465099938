import auth0 from "auth0-js";
import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const { setDataInCookies } = sessionManager;
const { AUTH0_ID_TOKEN } = keyTypeConstants;

const {
  METHOD_TYPE: { POST },
  API_END_POINT: { SIGN_IN, SIGN_UP },
} = httpConstants;
const headers = { "Content-Type": "application/json" };
export default class Auth0Service {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: window.location.origin,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
      responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
      grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
  }

  baseUrl = process.env.REACT_APP_TENANT_MICROSERVICE_URL;

  signIn = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + SIGN_IN, requestData, headers)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  signUp = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + SIGN_UP, requestData, headers)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  oAuthSignIn() {
    this.auth0.authorize({
      connection: "google-oauth2",
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
  }
  oAzureSignIn() {
    this.auth0.authorize({
      connection: process.env.REACT_APP_AZURE_AD_CONNECTION || "windowslive",
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
  }

  forgotPassword = ({ email, isPrivate = false }) => {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: isPrivate
            ? process.env.REACT_APP_AUTH0_REALM_PRIVATE
            : process.env.REACT_APP_AUTH0_REALM,
          email,
        },
        (err, res) => {
          if (err) return reject(err);
          resolve(res);
        }
      );
    });
  };

  userInfo() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        this.auth0.client.userInfo(authResult.accessToken, (userErr, user) => {
          if (userErr) return reject(userErr);
          setDataInCookies(authResult.idToken, AUTH0_ID_TOKEN, 1);
          resolve(user);
        });
      });
    });
  }

  oAuthSignInPrivate() {
    this.auth0.authorize({
      connection: "google-oauth2",
      redirect_uri: `${window.location.origin}/user/sign-up`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
  }
  oAzureSignInPrivate() {
    this.auth0.authorize({
      connection: "windowslive",
      redirect_uri: `${window.location.origin}/user/sign-up`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
  }
}
